window.addEventListener('load', function(){
    var allimages= document.getElementsByTagName('img');
    for (var i=0; i<allimages.length; i++) {
        if (allimages[i].getAttribute('data-src')) {
            allimages[i].setAttribute('src', allimages[i].getAttribute('data-src'));
        }
        if (allimages[i].getAttribute('data-srcset')) {
            allimages[i].setAttribute('srcset', allimages[i].getAttribute('data-srcset'));
        }
    }
}, false);

jQuery(document).ready(function($) {
    $(window).on('load', function() {
        // Loader.
        $('#loading').fadeOut(500, function() {
            $('body').removeClass('loading');
        });
    });

    // Toggle classes for mobile nav.
    $('.navbar-toggler').click(function() {
        $(this).toggleClass('navbar-toggler--toggled');
        $('body').toggleClass('shift');
    });

    // Toggle class for sticky nav on large screens and up.
    if ($(window).width() > 991) {
        $(window).scroll(function() {
            if ($(window).scrollTop() > ($(window).innerHeight() * 0.25)) {
                $('.navbar').addClass('navbar--scrolled');
            } else {
                $('.navbar').removeClass('navbar--scrolled');
            }
        });
    }

    // Smooth scroll to element by ID.
	$('a[href^="#"]:not(a[href="#"]):not(a[data-toggle="collapse"])').click(function(e) {
		e.preventDefault();

		var target = this.hash;
		var $target = $(target);

        if ($(window).width() > 991) {
            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - 60
            }, 900, 'swing');
        } else {
            $('html, body').stop().animate({
                'scrollTop': $target.offset().top
            }, 900, 'swing');
        }
	});

    // Animate scroll on logo click.
    $('.navbar-brand, .footer-brand').click(function(e) {
		e.preventDefault();

		$('html, body').stop().animate({
			'scrollTop': 0
		}, 900, 'swing');
	});

    // Do this stuff on load.
    $(window).on('load', function() {
        // Typed.js animation in hero.
        var typed = new Typed('#typed', {
            stringsElement: '#typed-strings',
            typeSpeed: 10,
            startDelay: 2000,
            backSpeed: 50,
            smartBackspace: true,
            backDelay: 1500,
            loop: true,
        });

        // Go away spam.
        var emailName = 'josh';
        var emailDomain = 'joshuad';
        var emailTLD = 'me';
        $('.contact-list__email').delay(1000).fadeOut('250', function(){
            $(this).replaceWith('<a href="mailto:' + emailName + '@' + emailDomain + '.' + emailTLD + '">' + emailName + '@' + emailDomain + '.' + emailTLD + '</a>').fadeIn('250');
        });
    });

    // Portfolio modal
    var portfolio_modal_left = $('#portfolio-modal__left');
    var portfolio_modal_label = $('#portfolio-modal__label');
    var portfolio_modal_link = $('#portfolio-modal__link');
    var portfolio_modal_description = $('#portfolio-modal__description');
    var portfolio_modal_tech_list = $('#portfolio-modal__techs');

    $('.portfolio-item').click(function(e) {
        e.preventDefault();

        var $this = $(this);
        var img_dir = './assets/img/';
        var img_name = $this.attr('data-mockup');
        var techs = jQuery.parseJSON($this.attr('data-techs'));
        var icons = '';

        var mockup = '<img src="' + img_dir + img_name + '-sm.jpg" srcset="' + img_dir + img_name + '-sm.jpg 400w, ' + img_dir + img_name + '-md.jpg 800w, ' + img_dir + img_name + '-lg.jpg 1600w">';

        $.each(techs, function(index, tech_item) {
            switch(tech_item) {
                case 'html':
                    tech_title = 'HTML5';
                    break;
                case 'css':
                    tech_title = 'CSS3';
                    break;
                case 'js':
                    tech_title = 'JavaScript';
                    break;
                case 'php':
                    tech_title = 'PHP';
                    break;
                case 'wp':
                    tech_title = 'WordPress';
                    break;
                case 'wpapi':
                    tech_title = 'WordPress REST API';
                    break;
                case 'woo':
                    tech_title = 'WooCommerce';
                    break;
                case 'grav':
                    tech_title = 'Grav';
                    break;
                case 'vuejs':
                    tech_title = 'Vue.js';
                    break;
                case 'angular':
                    tech_title = 'AngularJS';
                    break;
                case 'jquery':
                    tech_title = 'jQuery';
                    break;
                case 'bootstrap':
                    tech_title = 'Bootstrap';
                    break;
                case 'foundation':
                    tech_title = 'Foundation';
                    break;
                case 'bulma':
                    tech_title = 'Bulma';
                    break;
            }
            icons += '<li><img class="icon icon-list__icon icon-' + tech_item + '" src="./assets/img/icon-' + tech_item + '.svg"> ' + tech_title + '</li>';
        });

        portfolio_modal_left.html(mockup);
        portfolio_modal_label.text($this.attr('data-title'));
        portfolio_modal_link.text($this.attr('data-link-text'));
        portfolio_modal_description.text($this.attr('data-description'));
        portfolio_modal_tech_list.html(icons);

        if ($this.attr('data-link') !== '#') {
            portfolio_modal_link.attr("href", ($this.attr('data-link')));
        } else {
            portfolio_modal_link.addClass('disabled');
        }

        $('#portfolio-modal').modal();
    });

    $('#portfolio-modal').on('hidden.bs.modal', function(e){
        portfolio_modal_left.html('');
        portfolio_modal_label.text('');
        portfolio_modal_link.attr('href', '#');
        portfolio_modal_link.removeClass('disabled');
        portfolio_modal_link.text('');
        portfolio_modal_description.text('');
        portfolio_modal_tech_list.html('');
    });
});

// Parallax effect.
window.addEventListener('scroll', function(event) {
    var depth, i, layer, layers, len, movement, topDistance, translate3d;
    topDistance = this.pageYOffset;
    layers = document.querySelectorAll("[data-type='parallax']");
    for (i = 0, len = layers.length; i < len; i++) {
        layer = layers[i];
        depth = layer.getAttribute('data-depth');
        movement = -(topDistance * depth);
        translate = 'translate(0, ' + movement + 'px)';
        layer.style['-webkit-transform'] = translate;
        layer.style['-ms-transform'] = translate;
        layer.style.transform = translate;
    }
});

// Hero particles.
particlesJS('stars',
{
	"particles": {
		"number": {
			"value": 420,
			"density": {
				"enable": true,
				"value_area": 600
			}
		},
		"color": {
			"value": "#ffffff"
		},
		"shape": {
			"type": "circle",
			"stroke": {
				"width": 0,
				"color": "#000000"
			},
			"polygon": {
				"nb_sides": 5
			},
			"image": {
				"src": "img/github.svg",
				"width": 100,
				"height": 100
			}
		},
		"opacity": {
			"value": 0.9,
			"random": false,
			"anim": {
				"enable": true,
				"speed": 1,
				"opacity_min": 0.1,
				"sync": false
			}
		},
		"size": {
			"value": 1.5,
			"random": true,
			"anim": {
				"enable": true,
				"speed": 2,
				"size_min": 0,
				"sync": false
			}
		},
		"line_linked": {
			"enable": false,
			"distance": 150,
			"color": "#ffffff",
			"opacity": 0.4,
			"width": 1
		},
		"move": {
			"enable": true,
			"speed": 0,
			"direction": "top-right",
			"random": true,
			"straight": true,
			"out_mode": "out",
			"bounce": false,
			"attract": {
				"enable": false,
				"rotateX": 600,
				"rotateY": 1200
			}
		}
	},
	"interactivity": {
		"detect_on": "canvas",
		"events": {
			"onhover": {
				"enable": true,
				"mode": "bubble"
			},
			"onclick": {
				"enable": true,
				"mode": "push"
			},
			"resize": true
		},
		"modes": {
			"grab": {
				"distance": 400,
				"line_linked": {
					"opacity": 1
				}
			},
			"bubble": {
				"distance": 200,
				"size": 1,
				"duration": 3,
				"opacity": 1,
				"speed": 3
			},
			"repulse": {
				"distance": 200,
				"duration": 0.4
			},
			"push": {
				"particles_nb": 4
			},
			"remove": {
				"particles_nb": 2
			}
		}
	},
	"retina_detect": true
}

);

// Footer particles.
particlesJS('floaters',
{
    "particles": {
        "number": {
            "value": 420,
            "density": {
                "enable": true,
                "value_area": 800
            }
        },
        "color": {
            "value": "#4d8084"
        },
        "shape": {
            "type": "circle",
            "stroke": {
                "width": 0,
                "color": "#000000"
            },
            "polygon": {
                "nb_sides": 5
            },
            "image": {
                "src": "img/github.svg",
                "width": 100,
                "height": 100
            }
        },
        "opacity": {
            "value": 1,
            "random": true,
            "anim": {
                "enable": false,
                "speed": 0.5,
                "opacity_min": 0.5,
                "sync": false
            }
        },
        "size": {
            "value": 2,
            "random": true,
            "anim": {
                "enable": false,
                "speed": 40,
                "size_min": 0.5,
                "sync": false
            }
        },
        "line_linked": {
            "enable": false,
            "distance": 150,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 1
        },
        "move": {
            "enable": true,
            "speed": 1.5,
            "direction": "top",
            "random": true,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
                "enable": false,
                "rotateX": 100,
                "rotateY": 10000
            }
        }
    },
    "interactivity": {
        "detect_on": "canvas",
        "events": {
            "onhover": {
                "enable": false,
                "mode": "grab"
            },
            "onclick": {
                "enable": true,
                "mode": "push"
            },
            "resize": true
        },
        "modes": {
            "grab": {
                "distance": 400,
                "line_linked": {
                    "opacity": 1
                }
            },
            "bubble": {
                "distance": 400,
                "size": 40,
                "duration": 2,
                "opacity": 8,
                "speed": 3
            },
            "repulse": {
                "distance": 200,
                "duration": 0.4
            },
            "push": {
                "particles_nb": 4
            },
            "remove": {
                "particles_nb": 2
            }
        }
    },
    "retina_detect": true
}

);
